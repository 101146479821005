// extracted by mini-css-extract-plugin
export var aboutMe = "style--aboutMe--62aab";
export var aboutSection = "style--aboutSection--05fb7";
export var aboutWrapper = "style--aboutWrapper--9fd99";
export var dot = "style--dot--297ed";
export var header = "style--header--c842b";
export var headerLine = "style--headerLine--41f2b";
export var link = "style--link--add7f";
export var linkContainer = "style--linkContainer--9ae39";
export var links = "style--links--fc329";
export var pictureWrapper = "style--pictureWrapper--14ab8";
export var profilePicture = "style--profilePicture--059e6";
export var square = "style--square--d5ca1";
export var wrapper = "style--wrapper--3ddc0";